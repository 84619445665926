<template>
	<div ref="self" style="background: white; padding: 10px 20px;">
		<div v-if="isError" style="text-align: center">
			<i class="el-icon-error" style="color: #f56c6c; font-size: 50px"></i>
			<p>加载失败！</p>
			<p>({{ error }})</p>
			<el-button @click="load">重试</el-button>
		</div>
		<div v-show="!isError">
			<el-divider content-position="left">我的显示名称</el-divider>
			<el-input :value="info.nickname"></el-input>
			<el-divider content-position="left">我的手机号</el-divider>
			<el-input :value="info.phoneNumber"></el-input>
			<el-divider content-position="left">我的权限</el-divider>
			<manager-access-editor disabled :access="info.access" />
		</div>
	</div>
</template>

<script>
import ManagerAccessEditor from "../../components/ManagerAccessEditor.vue";
import { Loading, Divider, Input } from "element-ui";
export default {
	name: "ManagerInfo",
	components: { ManagerAccessEditor, ElDivider: Divider, ElInput: Input },
	data() {
		return {
			isError: false,
			error: "",
			info: {},
		};
	},
	async mounted() {
		return await this.load();
	},
	methods: {
		async load() {
			let loadingInstance = Loading.service({
				target: this.$refs.self,
				fullscreen: false,
			});
			try {
				const { data } = await this.$api.manager.manager.info();
				if (data.code === "OK") {
					this.$set(this, "info", data.data);
					this.isError = false;
					this.error = "";
				} else {
					this.isError = true;
					this.error = data.errmsg;
				}

				loadingInstance.close();
			} catch (e) {
				console.error(e);
				this.isError = true;
				this.error = e;
				loadingInstance.close();
			}
		},
	},
};
</script>

<style>
</style>